import React, { PureComponent } from 'react';
import { debounce } from 'lodash';
import { css } from 'emotion';
import { GrafanaTheme, textUtil } from '@grafana/data';
import { stylesFactory } from '@grafana/ui';
import { BrandingOptions } from './types';
import { PanelProps } from '@grafana/data';
import config from 'app/core/config';
import { getTemplateSrv } from '@grafana/runtime';
import lightBackground from './img/background_light.svg';

interface Props extends PanelProps<BrandingOptions> {}

interface State {
  clientName: string;
  clientLogo: string;
}

export class OndoanClientsBranding extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      clientName: this.processContent(props.options).clientName,
      clientLogo: getTemplateSrv().replace(props.options.clientLogo),
    };
  }

  updateHTML = debounce(() => {
    const processedContent = this.processContent(this.props.options);

    if (processedContent.clientName !== this.state.clientName) {
      this.setState({ clientName: processedContent.clientName });
    }

    if (processedContent.clientLogo !== this.state.clientLogo) {
      this.setState({ clientLogo: processedContent.clientLogo });
    }
  }, 150);

  componentDidUpdate(prevProps: Props) {
    this.updateHTML();
  }

  processContent(options: BrandingOptions): BrandingOptions {
    let { clientName, clientLogo } = options;

    if (!clientName) {
      clientName = 'Cliente desconocido';
    }

    return {
      clientName: this.interpolateAndSanitizeString(clientName),
      clientLogo: getTemplateSrv().replace(clientLogo),
    };
  }

  interpolateAndSanitizeString(content: string): string {
    const { replaceVariables } = this.props;

    content = replaceVariables(content, {}, 'html');

    return config.disableSanitizeHtml ? content : textUtil.sanitize(content);
  }

  render() {
    const styles = getStyles(config.theme);
    const { clientName, clientLogo } = this.state;

    const logo = getTemplateSrv().replace(
      `/public/img/ondoan_clients_branding/${config.theme.isDark ? 'dark' : 'light'}-${clientLogo}`
    );

    return (
      <div className={styles.container}>
        <h1 className={styles.title}>{clientName}</h1>
        <img className={styles.logo} src={logo}></img>
      </div>
    );
  }
}

const getStyles = stylesFactory((theme: GrafanaTheme) => {
  const backgroundImage = theme.isDark ? 'public/img/login_background_dark.svg' : lightBackground;

  return {
    logo: css`
      object-fit: contain;
      height: 100%;
      width: -moz-available;
      max-height: 100px;
      padding: 0.5rem 0;
      flex-basis: content;
    `,
    container: css`
      display: flex;
      flex-direction: row;
      background: url(${backgroundImage}) no-repeat;
      background-size: cover;
      height: 100%;
      align-items: center;
      padding: 0 16px;
      justify-content: space-between;
      padding: 0 ${theme.spacing.lg};

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        background-position: 0px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        padding: 0 ${theme.spacing.sm};
      }
    `,
    title: css`
      margin-bottom: 0;
      white-space: nowrap;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
      }

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        font-size: ${theme.typography.heading.h2};
      }
      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        font-size: ${theme.typography.heading.h3};
        white-space: normal;
      }
    `,
  };
});
