// Libraries
import defaults from 'lodash/defaults';

// Types
import { DataFrame } from '../types';
import { getFieldDisplayName } from '../field';
import { formattedValueToString } from '../valueFormats';
import * as XLSX from 'xlsx';
import { set } from 'lodash';

export interface ExcelConfig {
  convertTypes?: boolean;
  sheetName?: string;
}

export function toXLSX(data: DataFrame[], config?: ExcelConfig): string {
  if (!data) {
    return '';
  }

  config = defaults(config, {
    convertTypes: true,
  });

  const rows = [];

  for (const series of data) {
    const { fields } = series;
    const seriesLength = series.length;
    const fieldsLength = fields.length;

    for (let i = 0; i < seriesLength; i++) {
      const row: any = {};

      for (let j = 0; j < fieldsLength; j++) {
        const name = getFieldDisplayName(fields[j], series).replace(/"/g, '""');

        if (fields[j].display) {
          const value = fields[j].values.get(i);
          const displayValue = fields[j].display!(value);

          if (config.convertTypes && fields[j].config.unit === 'none' && fields[j].type === 'number') {
            row[name] = Number(formattedValueToString(displayValue));
          } else if (config.convertTypes && fields[j].type === 'time') {
            row[name] = new Date(value);
          } else {
            row[name] = formattedValueToString(displayValue);
          }
        }
      }

      rows.push(row);
    }
  }

  let sheetName = config.sheetName?.replace(/['*/:?\[\\\]]+/, '') || 'datos';

  if (sheetName.length > 31) {
    sheetName = sheetName.substring(0, 31);
  }

  const ws = XLSX.utils.json_to_sheet(rows);
  const wb = { Sheets: {}, SheetNames: [sheetName] };

  set(wb, ['Sheets', sheetName], ws);

  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
    Props: {
      Author: 'Ondoan Metriks',
      Company: 'Ondoan - Close solutions',
      Title: 'Exportación de Ondoan Metriks',
      Comments: 'Datos exportados de la plataforma Ondoan Metriks',
    },
  });

  return excelBuffer;
}
