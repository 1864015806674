import {
  BigValueColorMode,
  BigValueGraphMode,
  BigValueJustifyMode,
  BigValueTextMode,
  SingleStatBaseOptions,
} from '@grafana/ui';
import {
  FieldOverrideContext,
  ReducerID,
  VizOrientation,
  escapeStringForRegex,
  getFieldDisplayName,
  standardEditorsRegistry,
} from '@grafana/data';

import { PanelOptionsEditorBuilder } from '@grafana/data';

// Structure copied from angular
export interface DynamicStatPanelOptions extends SingleStatBaseOptions {
  graphMode: BigValueGraphMode;
  colorMode: BigValueColorMode;
  justifyMode: BigValueJustifyMode;
  textMode: BigValueTextMode;
  lastUpdateTimestamp: {
    field: string;
  };
  dynamicThresholds: {
    mode: DynamicThresholdsMode;
    defaultColor: string;
    low: {
      field: string;
      color: string;
    };
    alertLow: {
      field: string;
      color: string;
    };
    alarmLow: {
      field: string;
      color: string;
    };
    high: {
      field: string;
      color: string;
    };
    alertHigh: {
      field: string;
      color: string;
    };
    alarmHigh: {
      field: string;
      color: string;
    };
  };
}

export enum DynamicThresholdsMode {
  None = 'none',
  LowAndHigh = 'low_and_high',
  LowAndHighWithAlert = 'low_and_high_alert',
  LowAndHighWithAlarm = 'low_and_high_alarm',
  LowOnly = 'low_only',
  LowOnlyWithAlert = 'low_only_alert',
  LowOnlyWithAlarm = 'low_only_alarm',
  HighOnly = 'high_only',
  HighOnlyWithAlert = 'high_only_alert',
  HighOnlyWithAlarm = 'high_only_alarm',
}

export function addStandardDataReduceOptions(
  builder: PanelOptionsEditorBuilder<SingleStatBaseOptions>,
  includeOrientation = true,
  includeFieldMatcher = true,
  includeTextSizes = true
) {
  builder.addRadio({
    path: 'reduceOptions.values',
    name: 'Show',
    description: 'Calculate a single value per column or series or show each row',
    settings: {
      options: [
        { value: false, label: 'Calculate' },
        { value: true, label: 'All values' },
      ],
    },
    defaultValue: false,
  });

  builder.addNumberInput({
    path: 'reduceOptions.limit',
    name: 'Limit',
    description: 'Max number of rows to display',
    settings: {
      placeholder: '5000',
      integer: true,
      min: 1,
      max: 5000,
    },
    showIf: (options) => options.reduceOptions.values === true,
  });

  builder.addCustomEditor({
    id: 'reduceOptions.calcs',
    path: 'reduceOptions.calcs',
    name: 'Calculation',
    description: 'Choose a reducer function / calculation',
    editor: standardEditorsRegistry.get('stats-picker').editor as any,
    defaultValue: [ReducerID.lastNotNull],
    // Hides it when all values mode is on
    showIf: (currentConfig) => currentConfig.reduceOptions.values === false,
  });

  if (includeFieldMatcher) {
    builder.addSelect({
      path: 'reduceOptions.fields',
      name: 'Fields',
      description: 'Select the fields that should be included in the panel',
      settings: {
        allowCustomValue: true,
        options: [],
        getOptions: async (context: FieldOverrideContext) => {
          const options = [
            { value: '', label: 'Numeric Fields' },
            { value: '/.*/', label: 'All Fields' },
          ];
          if (context && context.data) {
            for (const frame of context.data) {
              for (const field of frame.fields) {
                const name = getFieldDisplayName(field, frame, context.data);
                const value = `/^${escapeStringForRegex(name)}$/`;
                options.push({ value, label: name });
              }
            }
          }
          return Promise.resolve(options);
        },
      },
      defaultValue: '',
    });
  }

  if (includeOrientation) {
    builder.addRadio({
      path: 'orientation',
      name: 'Orientation',
      description: 'Stacking direction in case of multiple series or fields',
      settings: {
        options: [
          { value: VizOrientation.Auto, label: 'Auto' },
          { value: VizOrientation.Horizontal, label: 'Horizontal' },
          { value: VizOrientation.Vertical, label: 'Vertical' },
        ],
      },
      defaultValue: VizOrientation.Auto,
    });
  }

  if (includeTextSizes) {
    builder.addNumberInput({
      path: 'text.titleSize',
      category: ['Text size'],
      name: 'Title',
      settings: {
        placeholder: 'Auto',
        integer: false,
        min: 1,
        max: 200,
      },
      defaultValue: undefined,
    });

    builder.addNumberInput({
      path: 'text.valueSize',
      category: ['Text size'],
      name: 'Value',
      settings: {
        placeholder: 'Auto',
        integer: false,
        min: 1,
        max: 200,
      },
      defaultValue: undefined,
    });
  }
}

export function addUpdateTimestampOptions(builder: PanelOptionsEditorBuilder<DynamicStatPanelOptions>) {
  builder.addSelect({
    path: 'lastUpdateTimestamp.field',
    name: 'Last update timestamp field',
    description: 'Select the field that should be used to show the last update timestamp',
    category: ['Display'],
    settings: {
      allowCustomValue: false,
      options: [],
      getOptions: async (context: FieldOverrideContext) => {
        const options = [{ value: '', label: 'None' }];

        if (context && context.data) {
          for (const frame of context.data) {
            for (const field of frame.fields) {
              const name = getFieldDisplayName(field, frame, context.data);
              const value = name;
              options.push({ value, label: name });
            }
          }
        }

        return Promise.resolve(options);
      },
    },
    defaultValue: '',
  });
}

export function addDynamicThresholdsOptions(builder: PanelOptionsEditorBuilder<DynamicStatPanelOptions>) {
  builder
    .addSelect({
      path: 'dynamicThresholds.mode',
      name: 'Thresholds mode',
      description: 'Select the thresholds mode',
      category: ['Thresholds', 'Mode'],
      settings: {
        allowCustomValue: false,
        options: [
          { value: DynamicThresholdsMode.None, label: 'None' },
          { value: DynamicThresholdsMode.LowOnly, label: 'Low only' },
          { value: DynamicThresholdsMode.LowOnlyWithAlert, label: 'Low only with alert' },
          { value: DynamicThresholdsMode.LowOnlyWithAlarm, label: 'Low only with alert and alarm' },
          { value: DynamicThresholdsMode.HighOnly, label: 'High only' },
          { value: DynamicThresholdsMode.HighOnlyWithAlert, label: 'High only with alert' },
          { value: DynamicThresholdsMode.HighOnlyWithAlarm, label: 'High only with alert and alarm' },
          { value: DynamicThresholdsMode.LowAndHigh, label: 'Low and high' },
          { value: DynamicThresholdsMode.LowAndHighWithAlert, label: 'Low and high with alert' },
          { value: DynamicThresholdsMode.LowAndHighWithAlarm, label: 'Low and high with alert and alarm' },
        ],
      },
      defaultValue: DynamicThresholdsMode.None,
    })
    .addColorPicker({
      path: 'dynamicThresholds.defaultColor',
      name: 'Default color',
      description: 'Select the default color',
      category: ['Thresholds'],
      defaultValue: 'green',
    })
    .addSelect({
      path: 'dynamicThresholds.low.field',
      name: 'Field',
      description: 'Select the field that should be used as low threshold',
      category: ['Thresholds', 'Low threshold'],
      showIf: (currentConfig) =>
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHigh ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlarm ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowOnly ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowOnlyWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowOnlyWithAlarm,
      settings: {
        allowCustomValue: false,
        options: [],
        getOptions: async (context: FieldOverrideContext) => {
          const options = [{ value: '', label: 'None' }];

          if (context && context.data) {
            for (const frame of context.data) {
              for (const field of frame.fields) {
                const name = getFieldDisplayName(field, frame, context.data);
                const value = name;
                options.push({ value, label: name });
              }
            }
          }

          return Promise.resolve(options);
        },
      },
      defaultValue: '',
    })
    .addColorPicker({
      path: 'dynamicThresholds.low.color',
      name: 'Color',
      description: 'Select the low threshold color',
      category: ['Thresholds', 'Low threshold'],
      showIf: (currentConfig) =>
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHigh ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlarm ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowOnly ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowOnlyWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowOnlyWithAlarm,
      defaultValue: 'red',
    })
    .addSelect({
      path: 'dynamicThresholds.alarmLow.field',
      name: 'Field',
      description: 'Select the field that should be used as low alarm threshold',
      category: ['Thresholds', 'Low alarm threshold'],
      showIf: (currentConfig) =>
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlarm ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowOnlyWithAlarm,
      settings: {
        allowCustomValue: false,
        options: [],
        getOptions: async (context: FieldOverrideContext) => {
          const options = [{ value: '', label: 'None' }];

          if (context && context.data) {
            for (const frame of context.data) {
              for (const field of frame.fields) {
                const name = getFieldDisplayName(field, frame, context.data);
                const value = name;
                options.push({ value, label: name });
              }
            }
          }

          return Promise.resolve(options);
        },
      },
      defaultValue: '',
    })
    .addColorPicker({
      path: 'dynamicThresholds.alarmLow.color',
      name: 'Color',
      description: 'Select the low alarm threshold color',
      category: ['Thresholds', 'Low alarm threshold'],
      showIf: (currentConfig) =>
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlarm ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowOnlyWithAlarm,
      defaultValue: 'dark-yellow',
    })
    .addSelect({
      path: 'dynamicThresholds.alertLow.field',
      name: 'Field',
      description: 'Select the field that should be used as low alert threshold',
      category: ['Thresholds', 'Low alert threshold'],
      showIf: (currentConfig) =>
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlarm ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowOnlyWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowOnlyWithAlarm,
      settings: {
        allowCustomValue: false,
        options: [],
        getOptions: async (context: FieldOverrideContext) => {
          const options = [{ value: '', label: 'None' }];

          if (context && context.data) {
            for (const frame of context.data) {
              for (const field of frame.fields) {
                const name = getFieldDisplayName(field, frame, context.data);
                const value = name;
                options.push({ value, label: name });
              }
            }
          }

          return Promise.resolve(options);
        },
      },
      defaultValue: '',
    })
    .addColorPicker({
      path: 'dynamicThresholds.alertLow.color',
      name: 'Color',
      description: 'Select the low alert threshold color',
      category: ['Thresholds', 'Low alert threshold'],
      showIf: (currentConfig) =>
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlarm ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowOnlyWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowOnlyWithAlarm,
      defaultValue: 'yellow',
    })
    .addSelect({
      path: 'dynamicThresholds.alertHigh.field',
      name: 'Field',
      description: 'Select the field that should be used as high alert threshold',
      category: ['Thresholds', 'High alert threshold'],
      showIf: (currentConfig) =>
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlarm ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.HighOnlyWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.HighOnlyWithAlarm,
      settings: {
        allowCustomValue: false,
        options: [],
        getOptions: async (context: FieldOverrideContext) => {
          const options = [{ value: '', label: 'None' }];

          if (context && context.data) {
            for (const frame of context.data) {
              for (const field of frame.fields) {
                const name = getFieldDisplayName(field, frame, context.data);
                const value = name;
                options.push({ value, label: name });
              }
            }
          }

          return Promise.resolve(options);
        },
      },
      defaultValue: '',
    })
    .addColorPicker({
      path: 'dynamicThresholds.alertHigh.color',
      name: 'Color',
      description: 'Select the high alert threshold color',
      category: ['Thresholds', 'High alert threshold'],
      showIf: (currentConfig) =>
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlarm ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.HighOnlyWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.HighOnlyWithAlarm,
      defaultValue: 'yellow',
    })
    .addSelect({
      path: 'dynamicThresholds.alarmHigh.field',
      name: 'Field',
      description: 'Select the field that should be used as high alarm threshold',
      category: ['Thresholds', 'High alarm threshold'],
      showIf: (currentConfig) =>
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlarm ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.HighOnlyWithAlarm,
      settings: {
        allowCustomValue: false,
        options: [],
        getOptions: async (context: FieldOverrideContext) => {
          const options = [{ value: '', label: 'None' }];

          if (context && context.data) {
            for (const frame of context.data) {
              for (const field of frame.fields) {
                const name = getFieldDisplayName(field, frame, context.data);
                const value = name;
                options.push({ value, label: name });
              }
            }
          }

          return Promise.resolve(options);
        },
      },
      defaultValue: '',
    })
    .addColorPicker({
      path: 'dynamicThresholds.alarmHigh.color',
      name: 'Color',
      description: 'Select the high alarm threshold color',
      category: ['Thresholds', 'High alarm threshold'],
      showIf: (currentConfig) =>
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlarm ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.HighOnlyWithAlarm,
      defaultValue: 'dark-yellow',
    })
    .addSelect({
      path: 'dynamicThresholds.high.field',
      name: 'Field',
      description: 'Select the field that should be used as high threshold',
      category: ['Thresholds', 'High threshold'],
      showIf: (currentConfig) =>
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHigh ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlarm ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.HighOnly ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.HighOnlyWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.HighOnlyWithAlarm,
      settings: {
        allowCustomValue: false,
        options: [],
        getOptions: async (context: FieldOverrideContext) => {
          const options = [{ value: '', label: 'None' }];

          if (context && context.data) {
            for (const frame of context.data) {
              for (const field of frame.fields) {
                const name = getFieldDisplayName(field, frame, context.data);
                const value = name;
                options.push({ value, label: name });
              }
            }
          }

          return Promise.resolve(options);
        },
      },
      defaultValue: '',
    })
    .addColorPicker({
      path: 'dynamicThresholds.high.color',
      name: 'Color',
      description: 'Select the high threshold color',
      category: ['Thresholds', 'High threshold'],
      showIf: (currentConfig) =>
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHigh ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlarm ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.HighOnly ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.HighOnlyWithAlert ||
        currentConfig.dynamicThresholds.mode === DynamicThresholdsMode.HighOnlyWithAlarm,
      defaultValue: 'red',
    });
}
