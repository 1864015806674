import React from 'react';

const PermissionsInfo = () => (
  <div>
    <h5>¿Qué son los permisos?</h5>
    <p>
      Se utiliza el modelo de Lista de Control de Acceso (ACL) para limitar el acceso a las carpetas de escritorios. Se
      puede asignar permisos a un usuario o a un equipo para acceder a una carpeta o a un escritorio determinado.
    </p>
  </div>
);

export default PermissionsInfo;
