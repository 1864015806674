import React, { useEffect, useMemo, useState } from 'react';

import { PanelProps } from '@grafana/data';
import { useStyles, useTheme } from '@grafana/ui';

import { getBackendSrv } from 'app/core/services/backend_srv';
import { DashboardSearchHit } from 'app/features/search/types';
import { DashListOptions } from './types';
import { getStyles } from './styles';

type Dashboard = DashboardSearchHit & { clientName?: string; clientLogo?: string };

async function fetchDashboards(options: DashListOptions) {
  const params = {
    limit: 1,
    query: '',
    folderIds: null,
    tag: options.tags,
    type: 'dash-db',
  };

  const searched = await getBackendSrv().search(params);
  let dashMap = new Map<number, Dashboard>();

  searched.forEach((dash) => {
    dashMap.set(dash.id, {
      ...dash,
      clientName: options.clientName,
      clientLogo: options.clientLogo,
    });
  });

  return dashMap;
}

export function OndoanClientsShortcuts(props: PanelProps<DashListOptions>) {
  const theme = useTheme();
  const [dashboards, setDashboards] = useState(new Map<number, Dashboard>());

  useEffect(() => {
    fetchDashboards(props.options).then((dashes) => {
      setDashboards(dashes);
    });
  }, [props.options.clientName, props.options.clientLogo, props.options.tags]);

  const foundDashes = useMemo(() => {
    return [...dashboards.values()];
  }, [dashboards]);

  const css = useStyles(getStyles);
  return (
    <ul className={css.dashlist}>
      {foundDashes.map((dash) => (
        <li className={css.dashlistItem} key={`dash-${dash.id}`}>
          <div className={css.dashlistLink}>
            <div className={css.dashlistLinkBody}>
              <a className={css.dashlistTitle} href={dash.url}>
                <img
                  className={css.dashlistLogo}
                  src={
                    theme.isDark
                      ? `/public/img/ondoan_clients_branding/dark-${dash.clientLogo}`
                      : `/public/img/ondoan_clients_branding/light-${dash.clientLogo}`
                  }
                ></img>
                <div>
                  <div className={css.dashClientName}>{dash.clientName}</div>
                  <div className={css.dashTitle}>{dash.title}</div>
                </div>
              </a>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
