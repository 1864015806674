import { DashListOptions } from './types';
import { OndoanClientsShortcuts } from './OndoanClientsShortcuts';
import { PanelPlugin } from '@grafana/data';
import React from 'react';
import { TagsInput } from '@grafana/ui';

export const plugin = new PanelPlugin<DashListOptions>(OndoanClientsShortcuts).setPanelOptions((builder) => {
  builder
    .addTextInput({
      path: 'clientName',
      name: 'Nombre del cliente',
      description: 'Escribe el nombre del cliente',
      defaultValue: `Empresa S.A.`,
    })
    .addSelect({
      path: 'clientLogo',
      name: 'Logotipo del cliente',
      description: 'Selecciona el logotipo a mostrar',
      defaultValue: 'ondoan.svg',
      settings: {
        options: [
          { value: 'building.svg', label: 'Misc. - Edificio' },
          { value: 'factory.svg', label: 'Misc. - Fábrica' },
          { value: 'house.svg', label: 'Misc. - Vivienda' },
          { value: 'aena.png', label: 'Aena' },
          { value: 'ajuntament-campos.png', label: 'Ajuntament de Campos' },
          { value: 'aludium.png', label: 'Aludium' },
          { value: 'anticimex.png', label: 'Anticimex' },
          { value: 'ayto-los-silos.png', label: 'Ayto. Los Silos' },
          { value: 'bec.png', label: 'Bilbao Exhibition Centre' },
          { value: 'bolsa-bilbao.jpg', label: 'Bolsa de Bilbao' },
          { value: 'donostia-udala.png', label: 'Donostia Udala' },
          { value: 'draxton.png', label: 'Draxton' },
          { value: 'durango-kirolak.png', label: 'Durango Kirolak' },
          { value: 'pinto.png', label: 'Ayto. Pinto' },
          { value: 'eitb.png', label: 'EITB' },
          { value: 'eroski.svg', label: 'Eroski' },
          { value: 'gela-pedagogikoa.png', label: 'Gela Pedagogikoa' },
          { value: 'gobierno-vasco.png', label: 'Gobierno Vasco' },
          { value: 'govern-illes-balears.svg', label: 'Govern Illes Balears' },
          { value: 'granplaza2.png', label: 'Gran Plaza 2' },
          { value: 'grupo-nbi.png', label: 'Grupo NBI' },
          { value: 'gsr.png', label: 'GSR' },
          { value: 'holiday-inn-express.png', label: 'Holiday Inn Express' },
          { value: 'n-senora-candelaria.png', label: 'Hospital Universitario Candelaria' },
          { value: 'hotel-seminario.png', label: 'Hotel Seminario Derio' },
          { value: 'la-vaguada.png', label: 'La Vaguada' },
          { value: 'mondragon-unibertsitatea.png', label: 'Mondragon Unibertsitatea' },
          { value: 'mungia-udala.png', label: 'Mungia Udala' },
          { value: 'ondoan.svg', label: 'Ondoan' },
          { value: 'osakidetza.svg', label: 'Osakidetza' },
          { value: 'palacio-euskalduna.png', label: 'Palacio Euskalduna' },
          { value: 's-juan-de-dios.png', label: 'San Juan de Dios' },
          { value: 'torre-iberdrola.png', label: 'Torre Iberdrola' },
          { value: 'ulma.jpg', label: 'Ulma' },
          { value: 'wallbox.png', label: 'Wallbox' },
        ],
      },
    })
    .addCustomEditor({
      id: 'tags',
      path: 'tags',
      name: 'Etiquetas',
      description: 'Selecciona las etiquetas del dashboard a enlazar',
      defaultValue: [],
      editor(props) {
        return <TagsInput tags={props.value} onChange={props.onChange} />;
      },
    });
});
