import {
  BigValue,
  BigValueGraphMode,
  BigValueTextMode,
  DataLinksContextMenu,
  VizRepeater,
  VizRepeaterRenderValueProps,
} from '@grafana/ui';
import {
  DisplayValueAlignmentFactors,
  FieldDisplay,
  PanelProps,
  ThresholdsMode,
  getDisplayValueAlignmentFactors,
  getFieldDisplayValues,
} from '@grafana/data';
import { DynamicStatPanelOptions, DynamicThresholdsMode } from './types';
import React, { PureComponent } from 'react';

import { DataLinksContextMenuApi } from '@grafana/ui/src/components/DataLinks/DataLinksContextMenu';
import { config } from 'app/core/config';

export class DynamicStatPanel extends PureComponent<PanelProps<DynamicStatPanelOptions>> {
  renderComponent = (
    valueProps: VizRepeaterRenderValueProps<FieldDisplay, DisplayValueAlignmentFactors>,
    menuProps: DataLinksContextMenuApi
  ): JSX.Element => {
    const { timeRange, options } = this.props;
    const { value, alignmentFactors, width, height, count } = valueProps;
    const { openMenu, targetClassName } = menuProps;
    let sparkline = value.sparkline;

    if (sparkline) {
      sparkline.timeRange = timeRange;
    }

    let timestamp: number | undefined;

    this.props.data.series.forEach((s) => {
      const timestampField = s.fields.find((f) => f.name === this.props.options.lastUpdateTimestamp.field);

      if (timestampField) {
        timestamp = timestampField.values.get(timestampField.values.length - 1);
      }
    });

    return (
      <BigValue
        timestamp={timestamp}
        value={value.display}
        count={count}
        sparkline={sparkline}
        colorMode={options.colorMode}
        graphMode={options.graphMode}
        justifyMode={options.justifyMode}
        textMode={this.getTextMode()}
        alignmentFactors={alignmentFactors}
        text={options.text}
        width={width}
        height={height}
        theme={config.theme}
        onClick={openMenu}
        className={targetClassName}
      />
    );
  };

  getTextMode() {
    const { options, fieldConfig, title } = this.props;

    // If we have manually set displayName or panel title switch text mode to value and name
    if (options.textMode === BigValueTextMode.Auto && (fieldConfig.defaults.displayName || !title)) {
      return BigValueTextMode.ValueAndName;
    }

    return options.textMode;
  }

  renderValue = (valueProps: VizRepeaterRenderValueProps<FieldDisplay, DisplayValueAlignmentFactors>): JSX.Element => {
    const { value } = valueProps;
    const { getLinks, hasLinks } = value;

    if (hasLinks && getLinks) {
      return (
        <DataLinksContextMenu links={getLinks}>
          {(api) => {
            return this.renderComponent(valueProps, api);
          }}
        </DataLinksContextMenu>
      );
    }

    return this.renderComponent(valueProps, {});
  };

  getValues = (): FieldDisplay[] => {
    const { data, options, replaceVariables, fieldConfig, timeZone } = this.props;

    let lowThreshold: number | undefined;
    let lowAlertThreshold: number | undefined;
    let lowAlarmThreshold: number | undefined;
    let highThreshold: number | undefined;
    let highAlertThreshold: number | undefined;
    let highAlarmThreshold: number | undefined;

    switch (options.dynamicThresholds.mode) {
      case DynamicThresholdsMode.None:
        options.dynamicThresholds.low.field = '';
        options.dynamicThresholds.alertLow.field = '';
        options.dynamicThresholds.alarmLow.field = '';
        options.dynamicThresholds.high.field = '';
        options.dynamicThresholds.alertHigh.field = '';
        options.dynamicThresholds.alarmHigh.field = '';
        break;

      case DynamicThresholdsMode.LowOnly:
        options.dynamicThresholds.alertLow.field = '';
        options.dynamicThresholds.alarmLow.field = '';
        options.dynamicThresholds.high.field = '';
        options.dynamicThresholds.alertHigh.field = '';
        options.dynamicThresholds.alarmHigh.field = '';
        break;

      case DynamicThresholdsMode.LowOnlyWithAlert:
        options.dynamicThresholds.alarmLow.field = '';
        options.dynamicThresholds.high.field = '';
        options.dynamicThresholds.alertHigh.field = '';
        options.dynamicThresholds.alarmHigh.field = '';
        break;

      case DynamicThresholdsMode.LowOnlyWithAlarm:
        options.dynamicThresholds.high.field = '';
        options.dynamicThresholds.alertHigh.field = '';
        options.dynamicThresholds.alarmHigh.field = '';
        break;

      case DynamicThresholdsMode.HighOnly:
        options.dynamicThresholds.low.field = '';
        options.dynamicThresholds.alertLow.field = '';
        options.dynamicThresholds.alarmLow.field = '';
        options.dynamicThresholds.alertHigh.field = '';
        options.dynamicThresholds.alarmHigh.field = '';
        break;

      case DynamicThresholdsMode.HighOnlyWithAlert:
        options.dynamicThresholds.low.field = '';
        options.dynamicThresholds.alertLow.field = '';
        options.dynamicThresholds.alarmLow.field = '';
        options.dynamicThresholds.alarmHigh.field = '';
        break;

      case DynamicThresholdsMode.HighOnlyWithAlarm:
        options.dynamicThresholds.low.field = '';
        options.dynamicThresholds.alertLow.field = '';
        options.dynamicThresholds.alarmLow.field = '';
        break;
    }

    data.series.map((s) => {
      s.fields.map((f) => {
        if (f.config.thresholds?.steps) {
          f.config.thresholds.steps = [];
          f.config.thresholds.mode = ThresholdsMode.Absolute;
        }

        let fieldThreshold: number | undefined = undefined;

        for (let i = f.values.length - 1; i >= 0; i--) {
          const th = f.values.get(i);

          if (th === undefined || th === null) {
            continue;
          }

          fieldThreshold = th;
          break;
        }

        switch (f.name) {
          case options.dynamicThresholds.low.field:
            lowThreshold = fieldThreshold;
            break;

          case options.dynamicThresholds.alarmLow.field:
            lowAlarmThreshold = fieldThreshold;
            break;

          case options.dynamicThresholds.alertLow.field:
            lowAlertThreshold = fieldThreshold;
            break;

          case options.dynamicThresholds.alertHigh.field:
            highAlertThreshold = fieldThreshold;
            break;

          case options.dynamicThresholds.alarmHigh.field:
            highAlarmThreshold = fieldThreshold;
            break;

          case options.dynamicThresholds.high.field:
            highThreshold = fieldThreshold;
            break;
        }
      });
    });

    data.series.map((s) => {
      s.fields.map((f) => {
        if (options.dynamicThresholds.mode === DynamicThresholdsMode.None && f.config.thresholds?.steps) {
          f.config.thresholds.steps = [{ color: options.dynamicThresholds.defaultColor, value: -Infinity }];
        } else if (
          options.dynamicThresholds.mode === DynamicThresholdsMode.LowOnly &&
          lowThreshold !== undefined &&
          f.config.thresholds?.steps
        ) {
          f.config.thresholds.steps = [
            { color: options.dynamicThresholds.low.color, value: -Infinity },
            { color: options.dynamicThresholds.defaultColor, value: lowThreshold },
          ];
        } else if (
          options.dynamicThresholds.mode === DynamicThresholdsMode.LowOnlyWithAlert &&
          lowThreshold !== undefined &&
          lowAlertThreshold !== undefined &&
          f.config.thresholds?.steps
        ) {
          f.config.thresholds.steps = [
            { color: options.dynamicThresholds.low.color, value: -Infinity },
            { color: options.dynamicThresholds.alertLow.color, value: lowThreshold },
            { color: options.dynamicThresholds.defaultColor, value: lowAlertThreshold },
          ];
        } else if (
          options.dynamicThresholds.mode === DynamicThresholdsMode.LowOnlyWithAlarm &&
          lowThreshold !== undefined &&
          lowAlertThreshold !== undefined &&
          lowAlarmThreshold !== undefined &&
          f.config.thresholds?.steps
        ) {
          f.config.thresholds.steps = [
            { color: options.dynamicThresholds.low.color, value: -Infinity },
            { color: options.dynamicThresholds.alarmLow.color, value: lowThreshold },
            { color: options.dynamicThresholds.alertLow.color, value: lowAlarmThreshold },
            { color: options.dynamicThresholds.defaultColor, value: lowAlertThreshold },
          ];
        } else if (
          options.dynamicThresholds.mode === DynamicThresholdsMode.HighOnly &&
          highThreshold !== undefined &&
          f.config.thresholds?.steps
        ) {
          f.config.thresholds.steps = [
            { color: options.dynamicThresholds.defaultColor, value: -Infinity },
            { color: options.dynamicThresholds.high.color, value: highThreshold },
          ];
        } else if (
          options.dynamicThresholds.mode === DynamicThresholdsMode.HighOnlyWithAlert &&
          highThreshold !== undefined &&
          highAlertThreshold !== undefined &&
          f.config.thresholds?.steps
        ) {
          f.config.thresholds.steps = [
            { color: options.dynamicThresholds.defaultColor, value: -Infinity },
            { color: options.dynamicThresholds.alertHigh.color, value: highAlertThreshold },
            { color: options.dynamicThresholds.high.color, value: highThreshold },
          ];
        } else if (
          options.dynamicThresholds.mode === DynamicThresholdsMode.HighOnlyWithAlarm &&
          highThreshold !== undefined &&
          highAlertThreshold !== undefined &&
          highAlarmThreshold !== undefined &&
          f.config.thresholds?.steps
        ) {
          f.config.thresholds.steps = [
            { color: options.dynamicThresholds.defaultColor, value: -Infinity },
            { color: options.dynamicThresholds.alertHigh.color, value: highAlertThreshold },
            { color: options.dynamicThresholds.alarmHigh.color, value: highAlarmThreshold },
            { color: options.dynamicThresholds.high.color, value: highThreshold },
          ];
        } else if (
          options.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHigh &&
          lowThreshold !== undefined &&
          highThreshold !== undefined &&
          f.config.thresholds?.steps
        ) {
          f.config.thresholds.steps = [
            { color: options.dynamicThresholds.low.color, value: -Infinity },
            { color: options.dynamicThresholds.defaultColor, value: lowThreshold },
            { color: options.dynamicThresholds.high.color, value: highThreshold },
          ];
        } else if (
          options.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlert &&
          lowThreshold !== undefined &&
          lowAlertThreshold !== undefined &&
          highThreshold !== undefined &&
          highAlertThreshold !== undefined &&
          f.config.thresholds?.steps
        ) {
          f.config.thresholds.steps = [
            { color: options.dynamicThresholds.low.color, value: -Infinity },
            { color: options.dynamicThresholds.alertLow.color, value: lowThreshold },
            { color: options.dynamicThresholds.defaultColor, value: lowAlertThreshold },
            { color: options.dynamicThresholds.alertHigh.color, value: highAlertThreshold },
            { color: options.dynamicThresholds.high.color, value: highThreshold },
          ];
        } else if (
          options.dynamicThresholds.mode === DynamicThresholdsMode.LowAndHighWithAlarm &&
          lowThreshold !== undefined &&
          lowAlertThreshold !== undefined &&
          lowAlarmThreshold !== undefined &&
          highThreshold !== undefined &&
          highAlertThreshold !== undefined &&
          highAlarmThreshold !== undefined &&
          f.config.thresholds?.steps
        ) {
          f.config.thresholds.steps = [
            { color: options.dynamicThresholds.low.color, value: -Infinity },
            { color: options.dynamicThresholds.alarmLow.color, value: lowThreshold },
            { color: options.dynamicThresholds.alertLow.color, value: lowAlarmThreshold },
            { color: options.dynamicThresholds.defaultColor, value: lowAlertThreshold },
            { color: options.dynamicThresholds.alertHigh.color, value: highAlertThreshold },
            { color: options.dynamicThresholds.alarmHigh.color, value: highAlarmThreshold },
            { color: options.dynamicThresholds.high.color, value: highThreshold },
          ];
        }
      });
    });

    return getFieldDisplayValues({
      fieldConfig,
      reduceOptions: options.reduceOptions,
      replaceVariables,
      theme: config.theme,
      data: data.series,
      sparkline: options.graphMode !== BigValueGraphMode.None,
      timeZone,
    });
  };

  render() {
    const { height, options, width, data, renderCounter } = this.props;

    return (
      <VizRepeater
        getValues={this.getValues}
        getAlignmentFactors={getDisplayValueAlignmentFactors}
        renderValue={this.renderValue}
        width={width}
        height={height}
        source={data}
        itemSpacing={3}
        renderCounter={renderCounter}
        autoGrid={true}
        orientation={options.orientation}
      />
    );
  }
}
