import { css } from 'emotion';

import { GrafanaTheme } from '@grafana/data';
import { styleMixins, stylesFactory } from '@grafana/ui';

export const getStyles = stylesFactory((theme: GrafanaTheme) => ({
  dashlist: css`
    height: 100%;
    width: 100%;
  `,

  dashlistItem: css`
    position: relative;
    list-style: none;
    width: 100%;
    height: 100%;
  `,

  dashlistLink: css`
    ${styleMixins.listItem(theme)}
    display: flex;
    cursor: pointer;
    padding: 7px;
    width: 100%;
    height: 100%;
  `,

  dashlistLinkBody: css`
    flex-grow: 1;
  `,

  dashlistTitle: css`
    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,

  dashlistLogo: css`
    width: 100%;
    height: 100%;
    max-height: 70%;
    object-fit: contain;
  `,

  dashClientName: css`
    font-weight: bold;
  `,

  dashTitle: css`
    font-size: small;
  `,
}));
