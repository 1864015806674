import { TimeOption } from '@grafana/data';

export const quickOptions: TimeOption[] = [
  { from: 'now-5m', to: 'now', display: 'Últimos 5 minutos', section: 3 },
  { from: 'now-10m', to: 'now', display: 'Últimos 10 minutos', section: 3 },
  { from: 'now-15m', to: 'now', display: 'Últimos 15 minutos', section: 3 },
  { from: 'now-30m', to: 'now', display: 'Últimos 30 minutos', section: 3 },
  { from: 'now-1h', to: 'now', display: 'Última hora', section: 3 },
  { from: 'now-3h', to: 'now', display: 'Últimas 3 horas', section: 3 },
  { from: 'now-6h', to: 'now', display: 'Últimas 6 horas', section: 3 },
  { from: 'now-12h', to: 'now', display: 'Últimas 12 horas', section: 3 },
  { from: 'now-24h', to: 'now', display: 'Últimas 24 horas', section: 3 },
  { from: 'now-2d', to: 'now', display: 'Últimos 2 días', section: 3 },
  { from: 'now-7d', to: 'now', display: 'Últimos 7 días', section: 3 },
  { from: 'now-30d', to: 'now', display: 'Últimos 30 días', section: 3 },
  { from: 'now-90d', to: 'now', display: 'Últimos 90 días', section: 3 },
  { from: 'now-6M', to: 'now', display: 'Últimos 6 meses', section: 3 },
  { from: 'now-1y', to: 'now', display: 'Último año', section: 3 },
  { from: 'now-2y', to: 'now', display: 'Últimos 2 años', section: 3 },
  { from: 'now-5y', to: 'now', display: 'Últimos 5 años', section: 3 },
];

export const otherOptions: TimeOption[] = [
  { from: 'now-1d/d', to: 'now-1d/d', display: 'Ayer', section: 3 },
  { from: 'now-2d/d', to: 'now-2d/d', display: 'Anteayer', section: 3 },
  { from: 'now-7d/d', to: 'now-7d/d', display: 'Este día la semana pasada', section: 3 },
  { from: 'now-1w/w', to: 'now-1w/w', display: 'Semana anterior', section: 3 },
  { from: 'now-1M/M', to: 'now-1M/M', display: 'Mes anterior', section: 3 },
  { from: 'now-1y/y', to: 'now-1y/y', display: 'Año anterior', section: 3 },
  { from: 'now/d', to: 'now/d', display: 'Hoy', section: 3 },
  { from: 'now/d', to: 'now', display: 'Hoy hasta ahora', section: 3 },
  { from: 'now/w', to: 'now/w', display: 'Esta semana', section: 3 },
  { from: 'now/w', to: 'now', display: 'Esta semana hasta ahora', section: 3 },
  { from: 'now/M', to: 'now/M', display: 'Este mes', section: 3 },
  { from: 'now/M', to: 'now', display: 'Este mes hasta ahora', section: 3 },
  { from: 'now/y', to: 'now/y', display: 'Este año', section: 3 },
  { from: 'now/y', to: 'now', display: 'Este año hasta ahora', section: 3 },
];
